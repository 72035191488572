/* First import Bootstrap v3 glyphicons */
@import './../../ajs/webpack-components/npm/bootstrap/css/glyphicons.css';

/* Leaflet comes with some styling */
//@import './../../ajs/webpack-components/npm/leaflet.css';
//@import './../../ajs/webpack-components/npm/leaflet.draw.css';
//@import './../../ajs/webpack-components/npm/L.Control.Locate.css';

/* Leaflet Geoman */
@import 'leaflet/dist/leaflet.css';
@import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';

/* Import Bootstrap SCSS file and our theme */
@import 'variables';
@import 'bootstrap-theme';

/* Import Angular app styles */
@import 'forms';
@import 'typography';
@import 'map';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  word-break: break-word;
}

body {
  background-color: var(--clr-linen);
  color: var(--clr-jet);
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  font-size: 15px !important;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.3;
  margin: 0;
  min-height: 100vh;
  min-width: 100%;
  text-align: left;
  overflow: hidden auto;

  &.no-scroll {
    height: 100dvh;
    max-height: 100dvh;
    overflow: hidden;
  }
}

body > ngb-modal,
body > .modal {
  z-index: $z-overlay-modal !important; /* Set as important to override in AJS context */
}

app-root {
  align-items: stretch;
  background: white;
  display: flex;
  flex-flow: column nowrap;
  gap: 0;
  height: 100%;
  justify-content: flex-start;
  min-height: 100dvh;
  min-width: 100dvw;
  width: 100%;

  > * {
    min-height: 0; // Break overflowing words if flow: column
  }
}

html:has(dialog[open]) {
  overflow: hidden;
}

app-root > #headerAndNavigation {
  flex-basis: fit-content;
}

app-root > #main {
  flex: 1 0 100%;
  height: 100%;
  position: relative;
}

.contentMargin {
  display: block;
  height: 2em;
}

.content-padding-bottom {
  padding-bottom: 2em;
}

.content-padding-top {
  padding-top: 2em;
}

.btn-inline,
.btn--inline {
  display: inline-block;
  width: auto;
}

app-search-place ngb-typeahead-window.dropdown-menu {
  max-height: 70dvh;
  overflow-y: scroll;
  z-index: $z-search-place-typeahead;
  --bs-dropdown-zindex: $z-search-place-typeahead;

  @media(max-width: $br-content-medium) {
    button.dropdown-item {
      --bs-dropdown-item-padding-y: .75rem;
    }
  }
}


.carousel-control-prev, .carousel-control-next {
  // --clr-primary turned into filter using https://angel-rs.github.io/css-color-filter-generator/
  filter: brightness(0) saturate(100%) invert(29%) sepia(49%) saturate(471%) hue-rotate(124deg) brightness(100%) contrast(91%);
}

.carousel-indicators {
  margin: 1rem auto 0;
  position: unset;

  [data-bs-target] {
    background-color: var(--clr-primary);
  }
}

.pagination {
  margin: 0;
}

button.link, .btn-link {
  background-color: transparent;
  border: 0;
  padding: 0 .2rem;
}
