// Include Bootstrap defaults
@import 'bootstrap/scss/bootstrap';

// 2. Override defaults for Bootstrap
$primary: #25625b;
$secondary: $gray-600;

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
);

:root {
  --bs-info: var(--clr-primary);
  --bs-link-color: var(--clr-primary);

  --bs-primary: var(--clr-primary);
  --bs-primary-bg-subtle: var(--clr-accent-bg);
  --bs-primary-rgb: 85, 142, 135;
}

.btn-primary {
  background-color: var(--clr-primary);
  color: white !important;
}

.btn[disabled] {
  background-color: var(--clr-accent-bg);
}

// Custom styling for NgBootstrap Datepicker
ngb-datepicker {
  background-color: white;
  color: #000;

  .bg-primary {
    background-color: var(--clr-primary) !important;
    color: #fff !important;
  }

  .btn-light.text-muted.outside {
    color: #767676;
    font-style: italic;
    opacity: 1;
  }
}
