@use 'variables' as var;

/* This is a fix for a bug in Leaflet where they provide the wrong path to the images */
img.leaflet-marker-shadow {
  content: url('./../../ajs/img/marker-shadow.png') !important;
}

.leaflet-icon {
  display: block;
  font-size: 19px;
  height: 30px;
  line-height: 30px;
  width: 30px;
}

.report-filter {
  /*background: rgba(100,100,100,  0.1) ;*/
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: var.$z-map-report-filters;
}

ngb-datepicker.dropdown-menu.show {
  z-index: var.$z-overlay-map-all !important;
}

@media(max-width: var.$br-content-small) {
  ngb-datepicker.dropdown-menu.show {
    margin-left: -3em !important;
  }
}

/*searchIcon for search*/
/*icon buttons used in map*/
.icon-btn {
  cursor: pointer !important;
  font-size: 21px;
  padding: 4px;
}

.icon-btn:hover {
  background-color: #f2f2f2 !important;
}

.report-filter .filter {
  min-height: 51px;
}

.report-filter .toggle-search {
  margin-top: 0.5em;
}

#searchInFilterParent {
  margin-top: 7px;
}

.searchMapInput {
  left: 58px;
  max-width: 400px !important;
  min-width: 250px;
  position: absolute;
  top: -2px;
  width: 30%;
  z-index: var.$z-map-report-filters;
}

.searchInFilterIcon {
  /*margin-left:   2px;*/
  background: #ffffff none repeat scroll 0 0;
  border-radius: 5px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  color: grey;
  font-size: 21px;
}

.searchInMapIcon {
  background: #ffffff none repeat scroll 0 0;
  border-radius: 5px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  color: grey;
  margin-left: 5px;
  margin-top: 5px;
  text-align: center;
}

.form-control-custom {
  border-left: none;
  margin-right: 0;
  min-width: 75px;
  padding-left: 1px;
  padding-right: 1px;
}

/*reporttext under filter*/
.mapInfo {
  background: rgba(255, 255, 255, 0.6);
  margin-top: 4px;
  padding: 4px;
}

/* on mobile use smaller min-width */
@media screen and (max-width: 480px) {
  .mapInfo {
    min-width: 250px;
  }
}

/*fra button in filter*/
.btn-custom {
  background: rgba(255, 255, 255, 1);
  border-color: #dcdcdc;
  border-right: none;
  color: grey;
  padding-left: 1px;
  pointer-events: none;
  width: 30px !important;
}

.leaflet-top {
  top: 1em !important;
}

.leaflet-top.leaflet-left {
  top: 3em !important;
}

#leaflet.draw >.leaflet-control-container > .leaflet-top.leaflet-left {
  top: 5em !important;
}

#leaflet-embed >.leaflet-control-container > .leaflet-top.leaflet-left {
  top: 1em !important;
}

.leaflet-bar {
  background: #ffffff;
  border: 2px solid #aaaaaa !important; /* Override since some receive multiple layered rgba's, hard to match */
  border-radius: 4px;
  color: #558e87;
}

.leaflet-control-scale {
  background: transparent;
  border: none !important;
  padding: 0.35rem;
}

.leaflet-popup-content-wrapper {
  max-height: min(500px, 50dvh);
  overflow: auto;
}

/*
 * Overrides for specific control positions
 */
.leaflet-bottom .leaflet-control-scale {
  margin-bottom: 2px !important;
  margin-left: 3px !important;
}

.leaflet-top {
  .leaflet-control a {
    display: block;
    padding: 0;
  }

  .leaflet-control a:hover {
    text-decoration: none;
  }

  .leaflet-control a,
  .leaflet-control span.leaflet-icon {
    line-height: 33px;
  }

  .leaflet-control .bi::before {
    vertical-align: text-top;
  }

  .leaflet-control-zoom-in,
  .leaflet-control-zoom-out,
  .leaflet-bar-part-single {
    align-content: center;
    display: grid !important;
    justify-content: center;
  }

  .leaflet-control-layers-toggle {
    background: none !important;
    font-size: 19px;
    line-height: 30px;
    text-align: center;
  }

  .leaflet-control-layers-toggle:hover {
    background: #f4f4f4 !important;
  }

  .leaflet-control-layers-expanded {
    z-index: var.$z-overlay-map-right;
  }

  .leaflet-bar > div {
    height: 30px;
    width: 30px;
  }
}

/**
 * Overrides for Leaflet Geoman styles
 */
.leaflet-pm-toolbar .control-icon {
  /*
   * Apply filters to colorize the svg backgrounds Geoman uses. Invert to "apply a color" then tune that color
   * @see https://css-tricks.com/solved-with-css-colorizing-svg-backgrounds/#comment-1626012
   */
  filter: invert(100%) brightness(0.45) sepia(1) hue-rotate(125deg) saturate(4);
  transform: scale(0.75);
}

.button-container .leaflet-pm-actions-container .leaflet-pm-action {
  background-color: #888;
  color: #fff !important;
}

.leaflet-control-container {
  z-index: var.$z-map-controls;
}

/**
 * Custom save drawings button. Added to map from app-leaflet-geoman but it's
 * using L.Control.extend so has to be styled from here
 */
.btn-purge-draw,
.btn-finish-draw {
  font-size: 0.8rem;
  height: 3em;
  min-width: 133px;
  pointer-events: all;
  text-align: left;

  @media(min-width: var.$br-content-medium) {
    font-size: 1.1rem;
    height: 4em;
    min-width: 180px;
  }

  > span {
    color: #eee;
    display: none;
    font-size: 0.8rem;

    @media(min-width: var.$br-content-medium) {
      display: block;
    }
  }
}

  /* Allow top controls to be scrolled in case they overflow the page size */
.leaflet-control-container .leaflet-top.leaflet-left,
.leaflet-control-container .leaflet-top.leaflet-right {
  height: calc(100dvh - 100px - 30px - 2em); // Subtract header, leaflet credits, original offset
  overflow: visible;
}

/*
 * Legend start
 */
.legend-container {
  align-items: flex-end;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;

  > .legend {
    flex: 0 0 30px;
  }

  a {
    padding: 0;
  }
}

.legend {
  background: #ffffff;
  border: none;
  border-radius: 4px;
  box-shadow: none;
  cursor: pointer;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  width: 30px;
}

.legend-popup {
  background-color: #ffffff;
  border: none;
  border-radius: 4px;
  box-shadow: none;
  color: #222222;
  cursor: default;
  font-size: 14px;
  max-width: 240px;
  padding: 5px;
  word-break: break-word;
}

.legend-icon {
  line-height: 31px;
}

.legend-item {
  margin: 0.5rem 0;
}

.legend-item,
.legend-label {
  max-inline-size: 40ch;
  text-wrap: balance;
  word-break: break-word;
}

.legend-icon-size {
  height: 12px;
  margin-right: 4px;
  width: 12px;
}

.legend-img-heading {
  font-weight: bold;
  margin-top: 2em;
}

.skogskade-flate-legend {
  background-color: red;
  display: inline-block;
  height: 12px;
  margin-bottom: 4px;
  margin-left: 1px;
  margin-right: 4px;
  vertical-align: middle;
  width: 12px;
}

.legend-img {
  height: auto;
  margin-left: 2px;
  margin-top: 4px;
}

.naturvern_klasser_omrade {
  height: auto;
  margin-left: 3px;
  margin-top: -2px;
}

/*
 * /END Legend
 */

.leaflet-control-layers-toggle {
  background-size: 20px;
  height: 30px !important;
  width: 30px !important;
}

.leaflet-control-layers-list {
  width: fit-content;
}

.leaflet-control-layers-list label {
  color: #222222;
  cursor: pointer;
}

.leaflet-control-layers-list label {
  color: #222222;
  cursor: pointer;
}

.leaflet-control-layers-list .leaflet-control-layers-base label,
.leaflet-control-layers-list .leaflet-control-layers-overlays label {
  margin: 0 0 0.5rem;
  padding: 0;

  > span {
    align-items: flex-start;
    display: flex;
    flex-flow: row wrap;
    gap: 0.5rem;
    justify-content: space-between;
    min-height: 1.1rem;

    > * {
      flex: 1 1 5%;
      margin: 0;
      min-width: 0; /* Break overflowing words if flow row */
      padding: 0;
    }

    > input {
      flex: 0 0 15px;
      height: 100%;
      margin-top: 3px;
    }

    > span {
      flex: 1 1 50%;
    }
  }
}

.leaflet-control.leaflet-control-layers {
  align-items: flex-end;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  padding: 0 !important;
}
.leaflet-control-layers-expanded {
  .leaflet-control-layers-list {
    min-width: 175px;
    padding: 0.75rem;
  }
}

.leaflet-draw-actions a {
  color: white !important;
}

/*cal button in filter*/
.btn-custom-cal {
  padding-left: 1px;
  padding-right: 1px;
  width: 30px;
}

.btn-custom-search {
  width: 30px !important;
}

.btn-custom-search i {
  margin-left: -5px;
}

.btn-custom-add-find {
  margin-left: 5px;
}

/* For normal cellphone in portrait f.eks Iphone 6/7/8, galaxy S5 */
@media only screen and (max-width: 480px) and (orientation: portrait) {
  .btn-custom-add-find {
    overflow: hidden;
    padding-left: 0px;
    padding-right: 0px;
    text-overflow: clip;
  }

  .form-control-search {
    min-width: 165px !important;
  }

  .input-search {
    margin-left: -50px !important;
  }
}

/* For small cellphone in portrait f.eks Iphone 5SE */
@media only screen and (max-width: 320px) and (orientation: portrait) {
  .btn-custom-add-find {
    max-width: 78%;
    overflow: hidden;
    padding-left: 0px;
    text-align: left;
    text-overflow: clip;
  }

  .form-control-search {
    min-width: 150px !important;
    width: 30%;
  }

  .input-search {
    margin-left: -50px !important;
  }
}

/*try to style filter row*/
.row-filter-in-directive {
  padding-top: 7px;
}

.filter-row {
  background: rgba(255, 255, 255, 0.6);
  border-radius: 5px;
}

.filter-row-hidden {
  background: rgba(0, 0, 0, 0);
}

.toggle-report-filter {
  display: table-cell;
  float: left;
  margin-left: -2px;
}

.toggle-report-btn {
  background: none;
  border: none;
  outline: none;
}

.report-filter-directive {
  display: table-cell;
}

.form-control-search {
  max-width: 600px !important;
  min-width: 260px;
  padding-right: 10px;
  width: 30%;
}

.search-place-draw {
  margin-left: 10px;
}

.btn-search {
  z-index: var.$z-search-btn;
}
