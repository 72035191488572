@use 'variables' as var;
@import 'bootstrap-icons';

/**
 * Links
 */
.link,
a {
  color: var(--clr-primary) !important;
  cursor: pointer;
  text-decoration: none;

  &:visited {
    color: darken(#558e87, 10%);
  }

  &:hover {
    color: var(--clr-jet);
    text-decoration: underline;
  }

  @media (hover: none) and (pointer: coarse) {
    // Increase trigger size for touch
    display: inline-block;
    padding: 0.5em;
  }
}

/**
 * Headings
 */
h1,
h2,
h3,
h4 {
  font-weight: 500;
  hyphens: auto;
  margin: 0.75em 0 0.25em;
  word-break: break-word;
}

h1:has(+ .subtitle),
h2:has(+ .subtitle),
h3:has(+ .subtitle),
h4:has(+ .subtitle) {
  margin-bottom: 0;
}

h1 + .subtitle,
h1 > .subtitle,
h2 + .subtitle,
h2 > .subtitle,
h3 + .subtitle,
h3 > .subtitle {
  display: block;
  font-size: var(--font-size-3);
  font-weight: 500;
  line-height: 1.2;
  margin: 0.1em 0 2em;

  @media (min-width: var.$br-content-medium) {
    margin-top: 0.2em;
  }
  @media (min-width: var.$br-content-wide) {
    margin-top: 0.3em;
  }
}

h1 {
  font-size: var(--font-size-4);
  font-style: normal;
  line-height: 1.4;
  text-align: left;
}

h2 {
  font-size: var(--font-size-3);
}

h3 {
  font-size: var(--font-size-2);
}

h4 {
  font-size: var(--font-size-1);
}

main h1 + .intro {
  font-size: var(--font-size-3);
  line-height: 1.3;
  margin-block-end: 2em;
}


.cursive {
  font-style: italic;
}
