/**
 * Breakpoints defined as scss vars to allow them to be used in media queries.
 */
$br-content-mini: 321px;
$br-content-small: 611px;
$br-content-medium: 768px;
$br-content-wide: 1080px;
$br-buffer: 50px;

/**
 * Defined hierarchy for z-indexes across application.
 *  All used z-indexes should be present in this list, and we should strive to always
 *  refer these variables instead of specifying direct values.
 *  This kind of list has proved necessary as we add various controls
 *  to the map from different places/components and these overlap on mobile views.
 */
$z-search-btn: 110;
// Leaflet defines .leaflet-control = 800
$z-map-controls: 800;
$z-map-control-left: 900;
$z-map-custom-controls: 1000;
$z-map-report-filters: 1050;
$z-map-search-place: 1060;
$z-map-control-right: 1100;
// Leaflet defines .leaflet-top, .leaflet-bottom = 1000
//$z-map-popups: 1100;
$z-search-place: 1500;
$z-search-place-typeahead: 1601;
// Overlays used when toggling popups, modals, overlays, or expanded states
//$z-overlay-map-left: 1150;
$z-overlay-map-right: 1200;
$z-overlay-map-all: 1650;
$z-overlay-header: 2500;
$z-overlay-modal: 2550;
$z-overlay-toasts: 2700;


/* Override default colors for Bootstrap */
//$primary: #558E87; // Original - does not pass A11y contrast ratios
$primary: #25625b; // Darker shade that passes contrast checks https://coolors.co/contrast-checker/ffffff-375f5a
//$danger: #ff4136;

:root {
  /**
   * Colors
   */
  --clr-accent-bg: #aac3c0;
  --clr-border-gray: #aaa;
  --clr-border-light: #ddd;
  --clr-jet: #333;
  --clr-linen: #faf8f4;
  --clr-primary: #{$primary};
  --clr-primary-light: #558e87;

  accent-color: $primary;

  --font-size-1: clamp(0.65rem, calc(0.7rem + 0.1vw), 0.9rem);
  --font-size-2: clamp(1rem, calc(0.98rem + 0.13vw), 1.13rem);
  --font-size-3: clamp(1.25rem, calc(1.2rem + 0.25vw), 1.5rem);
  --font-size-4: clamp(1.56rem, calc(1.48rem + 0.44vw), 2rem);
  --font-size-5: clamp(1.95rem, calc(1.81rem + 0.71vw), 2.66rem);

  --font-size-header: 28px;
  --font-size-navigation: 14px;
}
