.form-intro {
  font-weight: 600;
  margin: 1rem 0;
}

.form-section {
  margin-block: 0.75rem;

  .form-group {
    border: none;
    border-radius: 10px;
    padding: 0.75em 1.5em 1.2em;

    &:has(.form-validation.show) {
      padding-bottom: 0.75em;
    }

    label {
      font-weight: 600;
      font-size: 1.3em;
      line-height: 1.3;

      > .help-text {
        color: $secondary;
        display: block;
        font-weight: 500;
        font-size: 0.8em;
        line-height: 1.3;
      }
    }

    &:not(.inline),
    label:not(.inline) {
      display: block;
      margin-bottom: 0.35rem;
    }

    label:has(.help-text) {
      margin-bottom: 0.8rem;
    }

    .form-validation {
      color: var(--bs-danger);
      display: inline-flex;
      padding: 0.5rem 1ch 0 0;
    }

    .checkbox-group {
      align-items: center;
      display: flex;
      flex-flow: row wrap;
      font-size: 1.1em;
      gap: 0.4rem;

      > .checkbox-input {
        cursor: pointer;
        flex: 0 1 5px;
      }
      > .checkbox-label {
        cursor: pointer;
        flex: 1 1 50%;
        font-size: 0.9em;
        font-weight: 500;
        margin: 0;
      }
    }
  }
}

input,
textarea {
  border-radius: 3px;
  border: 1px solid var(--clr-border-gray);
  padding: 4px;
  width: 100%;
}

/**
 * The .required class is set by ReactiveFormsHelper.getFormControlClasses()
 */
.form-group.required:before {
  content: '*';
  color: var(--clr-primary);
  display: inline;
  font-weight: 600;
  float: left;
  margin: 0 0.25rem 0 -1rem;
}

.form-group:has(.ngb-dp-day > div.active):not(:has(.form-group)),
.form-group:has(a.btn-primary:focus):not(:has(.form-group)),
.form-group:has(a.btn-primary:focus-visible):not(:has(.form-group)),
.form-group:has(button.btn:focus-visible):not(:has(.form-group)),
.form-group:has(input:focus):not(:has(.form-group)),
.form-group:has(input:focus-visible):not(:has(.form-group)),
.form-group:has(select:focus):not(:has(.form-group)),
.form-group:has(select:focus-visible):not(:has(.form-group)),
.form-group:has(textarea:focus):not(:has(.form-group)),
.form-group:has(textarea:focus-visible):not(:has(.form-group)) {
  //border-left: 1px solid var(--clr-primary);
  background-color: #f1f1f1;
}

.form-group:has(.ng-touched.ng-invalid),
.form-group:has(.ng-touched.invalid) form.submitted .form-group:has(.ng-invalid),
form.submitted .form-group:has(.invalid) {
  //border-left: 1px solid var(--bs-danger);
  background-color: mistyrose;
}

.form-group.required:has(.ng-touched.ng-invalid):before {
  color: var(--bs-danger);
}

ngb-datepicker {
  background-color: white;
}

.btn.form-control:focus {
  &.btn-primary {
    background-color: var(--clr-primary);
  }
  &.btn-secondary {
    background-color: var(--clr-accent-bg);
    color: var(--clr-jet);
  }
}

.form-select {
  max-height: 2.5em;
}
